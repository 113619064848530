
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { IWarehouseListRequest, IWarehouse } from "@/types/warehouse";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { IProductListRequest, IProduct } from "@/types/product";
import { IOrder } from "@/types/order";
import { ILotListRequest, ILotCreateRequest, ILot } from "@/types/lot";
import { IUser } from "@/types/user";
import { IMovementStoringCreateRequest } from "@/types/movement_storing";
import { LotCreateRequest, LotRequest } from "@/models/lot";
import {
  MovementStoringCreateRequest,
  MovementStoringRequest,
  StoringDetails
} from "@/models/movement_storing";
import { IProductOrderStatusListRequest } from "@/types/product_order_status";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //movement_storing
  @Action("movement_storing/adminCreate")
  public create!: (params: IMovementStoringCreateRequest) => Promise<boolean>;

  @Mutation("movement_storing/clear")
  public clearMovementStoring!: () => void;

  //warehouse
  @Action("warehouse/adminGetList")
  public getWarehouses!: (params: IWarehouseListRequest) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Getter("warehouse/find")
  public findWarehouse!: (id: number) => IWarehouse;

  @Mutation("warehouse/clear")
  public clearWarehouse!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  //lot
  @Action("lot/adminGetList")
  public getLots!: (params: ILotListRequest) => Promise<boolean>;

  @Action("lot/adminCreateNoneMessage")
  public lotCreate!: (params: ILotCreateRequest) => Promise<boolean>;

  @Getter("lot/multiple")
  public lots!: ILot[];

  @Mutation("lot/clear")
  public clearLot!: () => void;

  //order
  @Action("order/adminGet")
  public getOrder!: (id: number) => Promise<boolean>;

  @Action("order/adminIsStoringComplete")
  public getIsStoringComplete!: (order_id: number) => Promise<boolean>;

  @Action("product_order_status/adminGetProductOrderStatus")
  public getOrders!: (
    params: IProductOrderStatusListRequest
  ) => Promise<boolean>;

  @Getter("order/single")
  public order!: IOrder;

  @Getter("product_order_status/OrderSelectItem")
  public orders!: ISelectItem[];

  @Mutation("order/clear")
  public clearOrder!: () => void;

  @Mutation("product_order_status/clear")
  public clearProductOrderStatus!: () => void;

  // note
  @Action("note/adminUpdateNote")
  public updateNote!: (
    notes: [
      {
        id: number | null;
        content: string | null;
      }
    ]
  ) => Promise<boolean>;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //パラメータ定義
  public params: IMovementStoringCreateRequest =
    new MovementStoringCreateRequest();
  public lot_params_create: ILotCreateRequest = new LotCreateRequest();
  public order_by_product_id_params: IProductOrderStatusListRequest = {
    product_id: 0
  };

  //変数定義
  public transport_price: number | null = null;
  public transport_price_breakdown = "";
  public order_quantitys: number[] = [];
  public remaining_storing_quantitys: number[] = [];
  public maker_ids: number[] = [];
  public productOptions: number[] = [];
  public units: string[] = [];
  public order_unit_prices: number[] = [];
  public lot_nos: (string | null)[][] = [[null]];
  public submit_dialog = false;
  public valid = true;
  public lazy = false;
  public order_id = 0;
  public remaining_storing_check = false;
  public menu_arrival_date = [false];
  public isOrderId = false;
  public isQuantity = false;
  public isProduct: boolean[] = [true];
  public orderSelectbox = true;
  public disp = false;
  public is_disabled: boolean[] = [true];
  public is_readonly: boolean[] = [false];
  public notes: [
    {
      id: number | null;
      content: string | null;
    }
  ] = [
    {
      id: 0,
      content: null
    }
  ];

  //ルール設定
  public rules = {
    arrival_date: [(v: string) => !!v || "入荷日は必須です"],
    warehouse_id: [(v: number) => !!v || "入荷倉庫は必須です"],
    exchange_rate: [
      (v: number) => !!v || "為替レートは必須です",
      (v: number) => v > 0 || "0以下は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    buying_unit_price: [
      (v: number) => !!v || "仕入単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    remaining_storing_quantitys: [
      (v: number) => v >= 0 || "残入荷数量を超えています"
    ],
    transport_price: [
      (v: number) => !v || v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,4})?$/.test(v) ||
        "小数点以下4桁までで入力してください"
    ],
    transport_price_yen: [
      (v: number) => !v || v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    transport_unit_price: [
      (v: number) => !v || v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearMovementStoring();
    this.clearWarehouse();
    this.clearMaker();
    this.clearProduct();
    this.clearLot();
    this.clearOrder();
    this.clearProductOrderStatus();

    this.order_id = Number(this.$route.params.order_id);
    this.params.status = Vue.prototype.$movementStatus.storing;

    await Promise.all([
      this.getWarehouses({ per_page: 0 }),
      this.getMakers({ per_page: 0 }),
      this.getOrders(this.order_by_product_id_params)
    ]);

    //発注一覧からの場合
    if (this.order_id) {
      await this.getOrder(this.order_id);
      await this.setOrder();
      this.isOrderId = true;
      this.orderSelectbox = false;
    }

    //処理が終わったらtemplate表示
    this.disp = true;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //発注情報をセットする
  public async setOrder() {
    //order_idセット
    this.params.order_id = this.order_id;

    //supplier_idセット
    this.params.supplier_id = this.order.supplier_id;

    //発注登録での諸経費合計を輸送費にセット
    this.transport_price = this.order.misc_expense_plus_only;

    // 輸送費内訳をセット
    const transportPriceBreakdownList = this.order.order_price_tag.filter(
      priceTag => {
        return !priceTag.is_minus;
      }
    );
    transportPriceBreakdownList.forEach((priceTag, index) => {
      this.transport_price_breakdown = this.transport_price_breakdown.concat(
        priceTag.price_tag_name +
          "：" +
          (this.order.currency_type == 1
            ? "￥"
            : this.order.currency_type == 2
            ? "＄"
            : "€") +
          (this.order.currency_type != 1
            ? Number(Number(priceTag.price).toFixed(4)).toLocaleString()
            : Number(Number(priceTag.price).toFixed(2)).toLocaleString()) +
          (index != transportPriceBreakdownList.length - 1 ? "\n" : "")
      );
    });

    //残入荷数量がある商品で配列生成
    const remaining_order = this.order.order_product.filter(order_product => {
      return order_product.remaining_quantity != 0;
    });

    for (let [index, order_product] of remaining_order.entries()) {
      if (index > 0) {
        this.params.storings.push(new MovementStoringRequest());
        this.lot_nos.push([null]);
        this.isProduct.push(true);
        this.is_disabled.push(true);
        this.is_readonly.push(false);
        this.notes.push({
          id: 0,
          content: null
        });
      }

      //通貨が円の場合は発注単価を仕入単価に反映
      if (this.order.currency_type == 1) {
        this.params.storings[index].storing_details[0].buying_unit_price =
          Number(Number(order_product.buying_unit_price).toFixed(2));
      }

      //発注商品情報をセット------------------------------*
      //メーカー
      this.maker_ids[index] = order_product.maker_id;

      //メーカーに紐づく商品リスト取得
      await this.getProductSegments({
        maker_id: this.maker_ids[index],
        per_page: 0
      });
      this.$set(
        this.productOptions,
        index,
        this.makerFilterdSelectItem(this.maker_ids[index])
      );

      //商品
      this.params.storings[index].product_id = order_product.product_id;

      //単位
      this.units[index] = order_product.unit;
      this.order_unit_prices[index] = Number(
        Number(order_product.buying_unit_price).toFixed(2)
      );

      //発注数量
      this.order_quantitys.push(order_product.quantity);

      //残入荷数量
      this.remaining_storing_quantitys.push(order_product.remaining_quantity);

      //入荷倉庫
      this.params.storings[index].warehouse_id = this.order.warehouse_id;

      //発注商品メモ
      this.notes[index].id = order_product.note_id;
      this.notes[index].content = order_product.content;

      //中間テーブルorder_productのid
      this.params.storings[index].order_product_id = order_product.id;
      //---------------------------------------------------*
    }
  }

  // 登録確認画面
  public submitConfirm() {
    let now_remaining_storing_quantity = 0;
    for (let [index] of this.params.storings.entries()) {
      now_remaining_storing_quantity +=
        this.NowRemainingStoringQuantity(index) || 0;
      this.remaining_storing_check = now_remaining_storing_quantity != 0;
    }

    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    //lot_no登録及びlot_id取得
    for (let [index_storing, storing] of this.params.storings.entries()) {
      for (let [index, lot_no] of this.lot_nos[index_storing].entries()) {
        this.lot_params_create.lots.push(new LotRequest());
        this.lot_params_create.lots[index].lot_no = lot_no || null;
        this.lot_params_create.lots[index].product_id = storing.product_id;
        this.lot_params_create.lots[index].user_id = this.user.id;
      }

      result = await this.lotCreate(this.lot_params_create);

      this.lot_params_create.lots.splice(0);

      for (let [index_lot, lot] of this.lots.entries()) {
        storing.storing_details[index_lot].lot_id = lot.id || null;
      }
    }

    //transport_unit_priceのnullを0にする
    for (let [, storing] of this.params.storings.entries()) {
      for (let [, storing_detail] of storing.storing_details.entries()) {
        if (storing_detail.transport_unit_price == null) {
          storing_detail.transport_unit_price = 0;
        }
      }

      result = await this.lotCreate(this.lot_params_create);

      this.lot_params_create.lots.splice(0);

      for (let [index_lot, lot] of this.lots.entries()) {
        storing.storing_details[index_lot].lot_id = lot.id || null;
      }
    }

    //発注商品メモ更新
    if (result) {
      result = await this.updateNote(this.notes);
    }

    //入荷登録
    if (result) {
      result = await this.create(this.params);
    }

    //入荷済みチェック
    if (result) {
      result = await this.getIsStoringComplete(this.order_id);
    }

    //一つ前の画面へ遷移
    if (result) {
      this.$router.go(-1);
    }
  }

  //メーカが選択された際の挙動
  public async makerSelected(index_storing: number) {
    this.isProduct[index_storing] = this.maker_ids[index_storing]
      ? false
      : true;
    this.params.storings[index_storing].product_id = 0;

    await this.getProductSegments({
      maker_id: this.maker_ids[index_storing],
      per_page: 0
    });

    this.$set(
      this.productOptions,
      index_storing,
      this.makerFilterdSelectItem(this.maker_ids[index_storing])
    );
  }

  //商品が選択された際の挙動
  public async productSelected(index_storing: number) {
    if (this.params.storings[index_storing].product_id) {
      const product = this.findProduct(
        this.params.storings[index_storing].product_id
      );
      this.units[index_storing] = product.unit;
      await this.getLots({
        product_id: this.params.storings[index_storing].product_id,
        per_page: 0
      });
    } else {
      this.units[index_storing] = "";
    }
  }

  //入荷商品追加
  public appendStoringList() {
    this.params.storings.push(new MovementStoringRequest());
    this.lot_nos.push([null]);
    this.isProduct.push(true);
    this.is_disabled.push(true);
    this.is_readonly.push(false);
  }

  //入荷商品削除
  public deleteStoringList(index_storing: number) {
    this.order_quantitys.splice(index_storing, 1);
    this.remaining_storing_quantitys.splice(index_storing, 1);
    this.maker_ids.splice(index_storing, 1);
    this.notes.splice(index_storing, 1);
    this.productOptions.splice(index_storing, 1);
    this.units.splice(index_storing, 1);
    this.lot_nos.splice(index_storing, 1);
    this.order_unit_prices.splice(index_storing, 1);
    this.isProduct.splice(index_storing, 1);
    this.is_disabled.splice(index_storing, 1);
    this.is_readonly.splice(index_storing, 1);
    this.params.storings[index_storing].storing_details.splice(0);
    this.params.storings.splice(index_storing, 1);
  }

  //入荷ロット追加
  public appendList(index_storing: number) {
    this.lot_nos[index_storing].push(null);

    let storing_details = this.params.storings[index_storing].storing_details;
    storing_details.push(new StoringDetails());
    storing_details[storing_details.length - 1].buying_unit_price =
      storing_details[0].buying_unit_price;
    storing_details[storing_details.length - 1].transport_unit_price =
      storing_details[0].transport_unit_price;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //入荷ロット削除
  public deleteList(index_storing: number, index: number) {
    this.params.storings[index_storing].storing_details.splice(index, 1);
    this.lot_nos[index_storing].splice(index, 1);
  }

  // 在庫一覧からの入荷処理で発注番号が選択されたときの挙動
  public async orderSelected() {
    this.isOrderId = true;
    this.params.storings.splice(1);
    if (this.order_id) {
      await this.getOrder(this.order_id);
      await this.setOrder();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //リアクティブ残入荷数量計算
  public NowRemainingStoringQuantity(index_storing: number) {
    let storing_quantity = 0;
    this.params.storings[index_storing].storing_details.forEach(
      storing_detail => {
        storing_quantity += Number(storing_detail.quantity || 0);
      }
    );
    return this.remaining_storing_quantitys[index_storing]
      ? this.remaining_storing_quantitys[index_storing] - storing_quantity
      : null;
  }

  //輸送費単価計算
  public calcTransportUnitPrice() {
    let storing_quantity = 0;
    let transport_unit_price = 0;
    for (let [, storing] of this.params.storings.entries()) {
      for (let [, storing_detail] of storing.storing_details.entries()) {
        storing_quantity += Number(storing_detail.quantity || 0);
      }
    }

    if (!storing_quantity || !this.transport_price) return;

    transport_unit_price = Number(
      (this.transport_price / storing_quantity).toFixed(2)
    );

    for (let [, storing] of this.params.storings.entries()) {
      for (let [, storing_detail] of storing.storing_details.entries()) {
        storing_detail.transport_unit_price = transport_unit_price;
      }
    }
  }

  //外貨輸送費単価計算
  public calcExchangeTransportUnitPrice() {
    let storing_quantity = 0;
    let transport_unit_price = 0;
    for (let [, storing] of this.params.storings.entries()) {
      for (let [, storing_detail] of storing.storing_details.entries()) {
        storing_quantity += Number(storing_detail.quantity || 0);
      }
    }

    if (
      !storing_quantity ||
      !this.transport_price ||
      !this.params.exchange_rate
    )
      return;

    const exchange_transport_price =
      this.transport_price * this.params.exchange_rate;

    transport_unit_price = Number(
      (exchange_transport_price / storing_quantity).toFixed(2)
    );

    for (let [, storing] of this.params.storings.entries()) {
      for (let [, storing_detail] of storing.storing_details.entries()) {
        storing_detail.transport_unit_price = transport_unit_price;
      }
    }
  }

  //外貨両替計算
  public currencyExchange() {
    if (!this.params.exchange_rate || this.params.exchange_rate == 0) return;
    for (let [index, storing] of this.params.storings.entries()) {
      for (let [, storing_detail] of storing.storing_details.entries()) {
        storing_detail.buying_unit_price = Number(
          (this.order_unit_prices[index] * this.params.exchange_rate).toFixed(2)
        );
      }
    }
  }

  //数量チェック
  public quantityCheck(quantity: number) {
    this.isQuantity = quantity > 0;
  }

  //バリデーションチェック
  public updated() {
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }
}
